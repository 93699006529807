import * as React from "react"
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import * as Gatsby from 'gatsby';
import Layout from "../components/Layout";

function NotFound() {
  return (
      <Box textAlign="center" py={10} px={6} m={24}>
        <Heading
            display="inline-block"
            as="h2"
            size="2xl"
            bgGradient="linear(to-r, green.400, green.600)"
            backgroundClip="text">
          404
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Page Not Found
        </Text>
        <Text color={'gray.500'} mb={6}>
          The page you're looking for does not seem to exist
        </Text>

        <Button
            colorScheme="green"
            bgGradient="linear(to-r, green.400, green.500, green.600)"
            color="white"
            variant="solid">
          <Gatsby.Link to={'/'}>
          Go to Home
          </Gatsby.Link>
        </Button>
      </Box>
  );
}

export default function NotFoundPage() {
    return (
        <Layout>
            <NotFound/>
        </Layout>
    );
}
